<template>
  <div>
    <ToolBar style="margin-bottom: 20px"/>
<!--    <router-view/>-->
  </div>
</template>

<script>
import ToolBar from '@/components/ToolBar.vue';

export default {
  name: 'index',
  components: {
    ToolBar,
  },
  data() {
    return {
      /** icon - image filename in assets folder */
      list: [
        {
          label: 'Корзина',
          icon: 'basket.svg',
          path: 'shopping-carts',
          child: [],
        },
        {
          label: 'Заказы',
          path: 'orders',
          icon: 'order.svg',
          child: [
            {
              name: 'Заказы',
              path: 'orders-all',
            },
            {
              name: 'Доставки',
              path: 'orders-delivery',
            },
          ],
        },
        {
          label: 'Оценки',
          icon: 'star.svg',
          path: 'estimations',
          child: [],
        },
        {
          label: 'Отзывы',
          icon: 'feedbacks.svg',
          path: 'feedbacks',
          child: [],
        },
        {
          label: 'Покупатели',
          icon: 'user.svg',
          path: 'clients',
          child: [
            {
              name: 'Покупатели',
              path: 'clients-list',
            },
            {
              name: 'Возвраты',
              path: 'clients-refunds',
            },
          ],
        },
        {
          label: 'Обращения',
          icon: 'file-edit.svg',
          path: 'appeals',
          child: [],
        },
        // { label: 'Продажи', path: 'sales' },
        {
          label: 'Биллинг',
          icon: 'billing.svg',
          path: 'billing',
          child: [
            {
              name: 'Входящие',
              path: 'billing-in',
              child: [],
            },
            {
              name: 'Исходящие',
              path: 'billing-out',
              child: [],
            },
          ],
        },
        {
          label: 'Настройки биллинга',
          icon: 'billing.svg',
          path: 'settings',
          child: [],
        },
        {
          label: 'Продавцы',
          icon: 'store.svg',
          path: 'counterparties',
          child: [
            { name: 'Продавцы', path: 'suppliers' },
            { name: 'Договоры', path: 'contracts' },
          ],
        },
        {
          label: 'Витрина',
          icon: 'grid.svg',
          path: 'marketplace',
          child: [
            { name: 'Витрина', path: 'showcases' },
            { name: 'Каталоги', path: 'catalog-order' },
          ],
        },
        {
          label: 'Каталоги',
          icon: 'catalog-tree.svg',
          path: 'catalogs',
          child: [],
        },
        {
          label: 'Отчеты',
          icon: 'reports.svg',
          path: 'reports',
          child: [],
        },
        {
          label: 'Аналитика',
          icon: 'graph.svg',
          path: 'analytics',
          child: [],
        },
        {
          label: 'Маркетинг',
          icon: 'marketing.svg',
          path: 'marketing',
          child: [
            { name: 'Мероприятия', path: 'events' },
            { name: 'Промокоды', path: 'promocodes' },
          ],
        },
        {
          label: 'Управление контентом',
          icon: 'book-content.svg',
          path: 'content-mgmt',
          child: [],
        },
        {
          label: 'Новости',
          icon: 'news.svg',
          path: 'news',
          child: [],
        },
        {
          label: 'Обращения продавцов',
          icon: '',
          path: 'jivo-seller',
          child: [],
        },
        // {
        //   label: 'Бонусы',
        //   icon: 'bonuses.svg',
        //   path: 'bonuses',
        //   child: [
        //     {
        //       name: 'Счета',
        //       path: 'bonuses-accounts',
        //       child: [],
        //     },
        //     {
        //       name: 'Операции',
        //       path: 'bonuses-operations',
        //       child: [],
        //     },
        //   ],
        // },
        {
          label: 'Home',
          icon: '',
          path: 'home',
          child: [],
        },
        // { label: 'Заявки', path: 'supplier-deliveries' },
        // { label: 'Клиенты', path: 'clients' },
        // { label: 'Закупки', path: 'procurements' },
        // { label: 'Звонки', path: 'calls' },
      ],
    };
  },
  created() {
    this.$store.dispatch('actionSetMenu', this.list);
  },
};
</script>

<style>

</style>
